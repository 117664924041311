.course-information {
  &__title {
    padding-inline: 2rem 1rem;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1.25rem 1.875rem;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list-item {
    position: relative;
    padding: 0.625rem 2rem;
    background-color: var(--clr-light-grey);
    border-radius: 5px;

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 8px;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      background-color: var(--clr-primary);
    }

    p {
      color: var(--clr-secondary);
      font-weight: 700;
    }
  }

  &__list-item-title {
    font-size: var(--fs-300);
  }
}
