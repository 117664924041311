@use '../abstracts/mixins' as *;
@use '../utilities/container';

.hero {
  // @extend .container !optional;
  margin-block-start: 0;

  &__image,
  picture {
    height: 250px;
    width: 100%;
    object-fit: cover;
    max-width: 1920px;
    margin-inline: auto;
  }

  &__title {
    margin-block-start: 2.5rem;
    margin-block-end: 1.5rem;
    hyphens: auto;
    text-align: left;
  }

  &__title--has-type {
    margin-block-start: 0.75rem;
    margin-block-end: 2.5rem;
  }

  &__product-type {
    margin-block-start: 2.5rem;
    margin-bottom: 0;
    font-weight: bold;
    font-size: var(--fs-600);
  }

  &__product-type,
  &__title {
    padding-inline: 1rem;
  }

  &:has(+ .course-header) {
    .hero__title {
      margin-block-end: 0rem;
    }
  }
}

@include mq(small) {
  .hero {
    &__image,
    picture {
      height: 300px;
    }

    &:has(+ .course-header) {
      .hero__title {
        margin-block-end: 2.5rem;
      }
    }
  }
}

@include mq(medium) {
  .hero {
    &__product-type,
    &__title {
      padding-inline: 2rem 1rem;
    }

    &__title {
      hyphens: none;
    }

    &__image,
    picture {
      height: 500px;
    }
  }
}
