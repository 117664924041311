.read-more {
  overflow: hidden;
  position: relative;
  display: grid;
  grid-template-rows: 0fr;
  transition: 300ms;

  & > * {
    min-height: 200px;
  }

  &--extended {
    grid-template-rows: 1fr;
  }

  &__overlay {
    background: linear-gradient(to bottom, transparent, white);
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: absolute;
    inset: 0;
    pointer-events: none;

    transition: display 300ms;
    transition-behavior: allow-discrete;

    @starting-style {
      display: flex;
    }

    svg {
      width: 16px;
      height: 16px;
    }
  }

  &__overlay-button {
    display: inline-flex;
    gap: 0.25rem;
    align-items: center;
    border: none;
    background: transparent;
    font-weight: 700;
    text-decoration: underline;
    color: var(--clr-secondary);
    text-underline-offset: 2px;
    cursor: pointer;
    pointer-events: initial;
  }

  &--extended &__overlay {
    display: none;
  }
}
