@use '../abstracts/mixins' as *;

.eye-catcher {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background: var(--clr-secondary);
  color: var(--clr-white);
  border-radius: 1000px;
  justify-self: flex-end;
  width: 130px;
  height: 130px;
  transform: rotate(-20deg);
  margin-inline: auto;

  &__link:focus-visible:focus-within &__text {
    outline: 4px solid var(--clr-secondary);
    outline-offset: 4px;
  }

  & b,
  & strong {
    line-height: 1;
    font-weight: 700;
  }

  &__icon {
    width: 48px;
    height: 48px;
  }

  &__link {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 1.25rem;
  }

  &__text {
    text-align: center;
    font-size: var(--fs-600);
    color: var(--clr-white);
    line-height: 1;

    & p {
      margin-top: -0.75rem;
    }
  }
}

@include mq(small) {
  .eye-catcher {
    --spacer: 0;

    width: 150px;
    height: 150px;
    margin-inline: 0;

    &__text {
      display: inline-flex;
      z-index: 1;
    }
  }
}
