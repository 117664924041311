@use '../abstracts/mixins' as *;
@use '../utilities/container';

.streamfield {
  margin-block-end: var(--spacer-sm);
  border-radius: 5px;

  & & {
    padding: 0;
    width: min(100% - 2rem, 1200px);

    @include mq(big) {
      width: 100%;
    }

    @media screen and (max-width: 40rem) {
      margin-inline: 0;
      width: 100%;
    }
  }

  & &:first-child {
    margin-block-start: 0;
  }

  & &:last-child {
    margin-block-end: 0;
  }
}

@include mq(medium) {
  .streamfield {
    margin-block-end: var(--spacer-lg);

    .container {
      width: min(100%, 1200px);
    }
  }
}
