@use '../abstracts/mixins' as *;

.rating {
  display: grid;
  grid-auto-flow: column;
  align-content: center;
  justify-items: center;
  grid-template-rows: repeat(2, auto);
  width: fit-content;

  &__stars {
    display: inline-flex;
    align-items: center;
  }

  &__star {
    display: inline-flex;
    width: 30px;
    height: 30px;
    position: relative;

    & > svg {
      position: absolute;
      inset: 0;
    }
  }

  p {
    font-size: var(--fs-300);
    margin-block-start: 1em;
    margin-inline-start: 0.3rem;
  }
}

@include mq(small) {
  .rating {
    justify-items: start;

    &__star {
      width: 40px;
      height: 40px;
    }

    p {
      font-size: var(--fs-500);
      margin-block-start: 0;
    }
  }
}
