@use '../abstracts/mixins' as *;

.reviews {
  &__text {
    margin-block-end: var(--spacer-sm);
  }

  .splide__track {
    padding-top: 60px;
  }
}

.review {
  position: relative;
  border: 3px solid var(--clr-primary);
  border-radius: 5px;
  padding: 4rem 1rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 250px;
  height: auto;
  max-height: 10000px;
  transition: all ease-in 300ms;

  &__iconwrapper {
    position: absolute;
    top: -50px;
    width: calc(100% - 2rem);
    display: grid;
    place-content: center;

    img {
      width: 100px;
      height: auto;
      object-fit: contain;
    }
  }

  &__text {
    color: var(--clr-secondary);
    font-size: var(--fs-400);

    p + p {
      margin-block-start: 1rem;
    }

    .extended__text {
      overflow: hidden;
      max-height: 0;
      margin-block-start: 0;
      transition: max-height ease-in 300ms;
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;

      &.extended {
        .extended__text {
          max-height: 1000px;
          margin-block-start: 1rem;
        }
      }
    }
  }

  &__read-more {
    background-color: transparent;
    border: none;
    text-align: left;
    width: fit-content;
    padding: 0.5rem 0;
    margin-block-start: 0.5rem;
    color: var(--clr-dark-grey);
    font-size: var(--fs-400);
    cursor: pointer;

    &:hover {
      color: var(--clr-primary);
    }
  }

  &__name {
    color: var(--clr-secondary);
    font-weight: 700;
    font-size: var(--fs-400);
    margin-block-start: 1rem;
  }
}

// minimize review when slided out of view to keep controls relative to visible content
.splide__slide:not(.is-visible) {
  .review {
    .review__text__wrapper.extended {
      .extended__text {
        max-height: 0;
      }
    }
  }
}
