@use '../abstracts/mixins' as *;
@use '../utilities/container';

.header {
  margin-block: 0;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  width: 100%;
  top: 0;
  isolation: isolate;
  z-index: 2;
  background-color: var(--clr-white);

  &__logo {
    img {
      width: 100%;
      max-width: 128px;
      height: auto;
    }
  }

  &__contact {
    display: none;
  }

  &__link {
    display: inline-block;
    padding: 1.5rem;
  }
}

@include mq(medium, max) {
  .header {
    height: 6rem;
    left: 0;
    right: 0;
  }
}

@include mq(medium) {
  .header {
    gap: 2.5rem;
  }
}
