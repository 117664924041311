.image {
  padding: 0;
  margin-inline: auto;
  width: 100%;

  &__element {
    width: 100%;
    height: auto;
    border-radius: 10px;
  }

  &__element[src$='.svg'] {
    border-radius: 0;
    width: 64px;
    display: block;
    margin-inline: auto;
  }

  &--full-width {
    grid-column: full-width !important;
  }
}
