.buttons {
  &__title {
    text-align: center;
    margin-block-end: 2rem;
    color: var(--clr-secondary);
  }

  &__list {
    display: grid;
    gap: 2rem;
    margin-inline: auto;
    max-width: 375px;
    width: 100%;
  }

  &__link {
    display: block;
    text-decoration: none;
    text-align: center;

    &:hover {
      text-decoration: underline;
    }
  }
}
