@use '../utilities/icon';

.event-finder {
  background-color: var(--clr-primary-light);
  color: var(--clr-secondary);
  padding: 2rem;

  & h2 {
    color: var(--clr-secondary);
  }

  & label {
    color: var(--clr-secondary);
  }

  &__icon {
    @extend .icon !optional;
    width: 164px;
    height: 164px;
    padding: 3rem;

    svg {
      stroke: var(--clr-secondary);
    }

    &--secondary svg {
      stroke: var(--clr-primary);
    }
  }

  &__title {
    margin-block: 1.25rem;
  }

  &__text {
    margin-block: 1.25rem 0.75rem;
    color: var(--clr-secondary);
    font-weight: 600;
  }

  &__description {
    line-height: 1.5;
    margin-block: 1.25rem;

    a {
      text-decoration: underline;
      color: var(--text-color);
    }

    & + & {
      margin-block-start: 1rem;
    }
  }

  &__form {
    display: grid;
    gap: 1rem;
  }

  &__location-search {
    --border-radius: 0.5rem;
    display: flex;

    & > :first-child {
      flex: 1;
    }

    button {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background-color: var(--clr-secondary);
      border: none;
      color: white;
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
      width: 4rem;
      padding: 0.5rem 0.875rem;
      cursor: pointer;

      &:focus-visible {
        outline: 4px solid var(--clr-secondary);
        outline-offset: 4px;
      }
    }

    button svg {
      stroke: white;
      height: auto;
      width: 100%;
    }

    input[type='search'] {
      padding: 0.5rem;
      border: 2px solid var(--clr-secondary);
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      font-size: var(--fs-400);
      flex: 1;

      &:focus-visible {
        outline: 2px solid var(--clr-secondary);
      }
    }
  }

  &__events {
    display: grid;
    gap: 1rem;
    margin-block-start: 4rem;
  }

  &__event {
    margin-block-start: var(--spacer-sm);
    display: grid;
    gap: 1rem;
  }

  &__event-title {
    font-weight: 700;
    font-size: var(--fs-600);
  }

  &__event-registration-button {
    background-color: white;
    color: var(--clr-text-color);
    border: 2px solid var(--clr-secondary);
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    overflow: hidden;

    &:hover {
      color: var(--clr-text-color);
    }

    & > span:first-child {
      display: grid;
      padding: 1.25rem;
      gap: 0.5rem;

      span:first-child {
        font-size: var(--fs-400);
      }

      span:last-child {
        font-size: var(--fs-500);
      }
    }

    & > span:last-child {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      width: 4rem;
      background-color: var(--clr-secondary);
      align-self: stretch;

      svg {
        height: 100%;
        width: 100%;
        max-width: 100px;
        color: white;
      }
    }
  }

  &__location-selection {
    display: grid;
    gap: 1rem;
    margin-block: 2rem;
  }
}
