@use '../abstracts/mixins' as *;
@use '../utilities/container';

.product-presentation {
  padding: var(--spacer-md) var(--spacer-sm);
  background-color: var(--clr-light-blue);
  color: var(--clr-secondary);

  &__title {
    color: var(--clr-secondary);
  }
}

.product-presentation__accordion {
  margin-top: 2.5rem;

  & .accordion__trigger[data-state='closed'] {
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
  }

  & .accordion__trigger {
    background-color: white;
    color: var(--clr-secondary);
    padding: 1.375rem 1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    justify-content: space-between;
    font-size: var(--fs-500);
  }

  & .accordion__icon-container {
    width: 40px;
    height: 40px;
    background-color: #252525;
    border-radius: 9999px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    & .accordion__icon {
      background-color: #252525;
      --inset: 0px;
      width: var(--fs-500);
      height: var(--fs-500);

      & span {
        position: absolute;
        transition: 300ms;
        background: white;
        border-radius: 2px;
      }

      /* Create the "+" shape by positioning the spans absolutely */
      & span:first-child {
        top: var(--inset);
        bottom: var(--inset);
        width: 2px;
        left: 50%;
        transform: translateX(-50%);
      }

      & span:last-child {
        left: var(--inset);
        right: var(--inset);
        height: 2px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  & .accordion__trigger {
    /* Morph the shape when the & is hovered over */
    &[data-state='open'] .accordion__icon span {
      transform: rotate(90deg);
    }

    &[data-state='open'] .accordion__icon span:last-child {
      left: 50%;
      right: 50%;
    }
  }

  & .accordion__content {
    background-color: white;
    border-bottom-left-radius: var(--border-radius);
    border-bottom-right-radius: var(--border-radius);
    padding-inline-start: 1rem;
  }
}

.button--product-presentation {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  &:hover {
    text-decoration: none;
  }
}
