.accordions {
  &__header {
    margin-block-start: 0;
  }

  span.accordion__icon {
    flex-shrink: 0;
  }

  &__text {
    margin-bottom: 1.5rem;
  }

  &__header,
  &__text {
    padding-inline: 2rem 1rem;
  }
}
