.benefits {
}

.benefit {
  background-color: var(--clr-dark-blue);
  border-radius: 5px;
  padding: 1rem 1rem 3rem;
  color: var(--clr-white);
  height: 100%;

  &__icon {
    background-color: var(--clr-white);
    border-radius: 1000px;
    width: 125px;
    height: 125px;
    padding: 2rem;
    width: fit-content;
    margin-inline: auto;
    margin-block-end: 2rem;
  }

  &__text {
    text-align: center;
    font-weight: 700;
    font-size: 1.5rem;
  }
}
