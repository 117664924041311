.splide {
  &__arrows {
    display: flex;
    justify-content: space-between;
    margin-block-start: var(--spacer-sm);
  }

  &__arrow {
    color: var(--clr-white);
    background-color: var(--clr-primary);
    border: none;
    border-radius: 10000px;
    width: 64px;
    aspect-ratio: 1 / 1;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
      flex-shrink: 0;
    }
  }

  &__arrow--prev {
    transform: scaleX(-1);
  }
}

.slider-fallback {
  &__track {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 1rem;

    & > *:nth-child(2) {
      display: none;
    }

    & > *:nth-child(3) {
      display: none;
    }

    & > *:nth-child(n + 3) {
      display: none;
    }
  }
}

@media screen and (min-width: 450px) {
  .slider-fallback {
    &__track {
      grid-template-columns: repeat(2, 1fr);

      & > *:nth-child(2) {
        display: initial;
      }
    }
  }
}

@media screen and (min-width: 700px) {
  .slider-fallback {
    &__track {
      grid-template-columns: repeat(3, 1fr);

      & > *:nth-child(3) {
        display: initial;
      }
    }
  }
}
