.products {
  &__title {
    padding-inline: 2rem 1rem;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    list-style: none;
    padding: 0;
    gap: 1rem;
  }

  &__list-item {
    border-radius: 5px;
    overflow: hidden;
    height: 100%;
  }

  &__list-item-text {
    background-color: var(--clr-light-grey);
    padding: 2rem 1rem;
    height: 100%;
  }

  &__list-item-image {
    min-height: 200px;
    width: 100%;
    height: auto;
    object-fit: cover;
  }

  &__type {
    font-size: var(--fs-400);
    font-weight: 700;
  }

  &__link {
    display: block;
    font-family: var(--font-exo);
    font-size: var(--fs-600);
    font-weight: 600;
    color: var(--clr-secondary);
    height: 100%;
  }
}
