@use '../utilities/container';
@use '../abstracts/mixins' as *;

* {
  // this is a bad idea, but works for now
  font-variation-settings:
    'wght' var(--font-weight, 375),
    'wdth' var(--font-width, 80);
  text-wrap: pretty;
}

body {
  font-weight: 400;
  font-size: var(--fs-500);
  color: var(--clr-dark-grey);
  font-family: var(--font-source-sans-pro);
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-exo);
  font-weight: normal;
  font-stretch: 100%;
  line-height: 1.1;
  color: var(--clr-secondary);
  text-wrap: balance;
}

// This is setting the font-sizes
// based on the ones in the abstract
// folder, go there to make updates

h1,
.h1 {
  font-size: var(--fs-800);
  font-weight: 700;
  margin-block: 2.5rem;
  color: var(--clr-secondary);
}

h2,
.h2 {
  font-size: var(--fs-700);
  font-weight: 700;
  margin-block-end: 1.25rem;
}

h3,
.h3 {
  font-size: var(--fs-600);
  font-weight: 700;
}

h4,
.h4 {
  font-size: var(--fs-500);
}

small,
.text-small {
  font-size: var(--fs-300);
}

strong {
  font-weight: var(--font-weight, 600);
}

a {
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

// p:not([class]),
// ul:not([class]),
// li:not([class]) {
//   max-width: 60ch;
// }
