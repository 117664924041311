.not-found {
  text-align: center;

  h2 {
    font-size: var(--fs-900);
  }

  .error__message {
    display: block;
    color: var(--clr-secondary);
    font-weight: 800;
    font-size: var(--fs-700);
    max-width: 28ch;
    margin-inline: auto;
  }

  a {
    display: block;
    width: fit-content;
    margin-block-start: var(--spacer-sm);
    margin-block-end: var(--spacer-lg);
    margin-inline: auto;
  }
}
