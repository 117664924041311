@use '../abstracts/mixins' as *;

.accordion {
  --padding: 1rem;

  &:not(:last-child) {
    margin-block-end: 1rem;
  }

  overflow: hidden;

  &__header {
    all: unset;
    display: flex;
  }

  &__trigger {
    all: unset;
    border-radius: var(--border-radius);
    background-color: var(--clr-secondary);
    color: var(--clr-white);
    flex: 1;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-size: var(--fs-500);
    font-weight: 600;
    line-height: 1.3;
    cursor: pointer;
  }

  &__title {
    padding: var(--padding) var(--padding) var(--padding) 0;
  }

  &__icon {
    color: #ecf0f1;
    min-width: var(--fs-500);
    min-height: var(--fs-500);
    border: 0;
    font-size: 1.5em;
    position: relative;

    svg {
      position: relative;
      top: 3px;
      transform: rotate(90deg);
      transition: transform 300ms;
    }

    [data-state='open'] & svg {
      transform: rotate(270deg);
    }
  }

  &__content[data-state='closed'] {
    display: none;
  }

  &__content {
    overflow: hidden;
    padding: var(--padding);
    padding-inline: 2rem 1rem;

    .richtext a {
      color: var(--clr-primary);
    }

    ul {
      & > * + * {
        margin-block-start: 0.5rem;
      }
    }

    &[data-state='open'] {
      // animation: slideDown 100ms linear forwards;
    }

    &[data-state='closed'] {
      // animation: slideUp 100ms linear forwards;
    }
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-accordion-content-height);
  }

  to {
    height: 0;
  }
}

@keyframes slideDown {
  from {
    height: 0;
  }

  to {
    height: var(--radix-accordion-content-height);
  }
}
